import React from "react"
import theme from "theme"
import { Theme, Text, Link, Box, Image, Section } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"services"} />
      <Helmet>
        <title>
          Наші послуги | Дослідіть наші послуги в автошколі Vexolux Pro
        </title>
        <meta
          name={"description"}
          content={"Приготуйтеся до всебічного досвіду водіння!"}
        />
        <meta
          property={"og:title"}
          content={
            "Наші послуги | Дослідіть наші послуги в автошколі Vexolux Pro"
          }
        />
        <meta
          property={"og:description"}
          content={"Приготуйтеся до всебічного досвіду водіння!"}
        />
        <meta property={"og:image"} content={"https://go.vexoluxpro.com/img/1.jpg"} />
        <link
          rel={"shortcut icon"}
          href={"https://go.vexoluxpro.com/img/icon 1.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://go.vexoluxpro.com/img/icon 1.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://go.vexoluxpro.com/img/icon 1.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://go.vexoluxpro.com/img/icon 1.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://go.vexoluxpro.com/img/icon 1.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://go.vexoluxpro.com/img/icon 1.png"}
        />
      </Helmet>
      <Components.Header />
      <Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          display="flex"
          width="50%"
          flex-direction="column"
          align-items="flex-start"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 0px"
          justify-content="center"
          lg-order="1"
        >
          <Text
            margin="0px 0px 24px 0px"
            color="--dark"
            font="--headline1"
            lg-text-align="center"
            sm-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
          >
            Наші послуги
          </Text>
          <Text
            margin="0px 0px 40px 0px"
            color="--greyD3"
            font="--lead"
            lg-text-align="center"
          >
            У автошколі Vexolux Pro ми надаємо широкий спектр послуг,
            розроблених для кожного типу учнів. Від початківців до досвідчених
            водіїв, наші програми розроблені таким чином, щоб кожен учень
            отримав персональну увагу та структурований навчальний план,
            необхідний для досягнення успіху.
          </Text>
          <Link
            href="/contacts"
            padding="12px 24px 12px 24px"
            color="--light"
            text-decoration-line="initial"
            font="--lead"
            border-radius="8px"
            margin="0px 16px 0px 0px"
            transition="background-color 0.2s ease-in-out 0s"
            sm-margin="0px 0px 16px 0px"
            sm-text-align="center"
            background="--color-primary"
            hover-transition="background-color 0.2s ease-in-out 0s"
            hover-background="--color-primary"
          >
            Контакти
          </Link>
        </Box>
        <Box
          display="flex"
          width="50%"
          justify-content="flex-end"
          lg-width="100%"
          align-items="flex-start"
          lg-margin="0px 0px 32px 0px"
          margin="0px 0px 0px 0px"
          padding="0px 0px 0px 32px"
          lg-padding="0px 0px 0px 0px"
          lg-justify-content="center"
        >
          <Image
            src="https://go.vexoluxpro.com/img/4.jpg"
            object-fit="cover"
            width="100%"
            height="100%"
            border-radius="24px"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            hover-transform="translateY(-10px)"
            min-height="600px"
            max-width="480px"
            sm-min-height="100vw"
          />
        </Box>
      </Section>
      <Section padding="0 0 0 0" quarkly-title="Statistics-7">
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          md-flex-direction="column"
        >
          <Box
            min-width="100px"
            min-height="100px"
            width="40%"
            md-width="100%"
            md-margin="0px 0px 50px 0px"
            sm-margin="0px 0px 35px 0px"
          >
            <Image
              src="https://go.vexoluxpro.com/img/5.jpg"
              display="block"
              width="auto"
              height="100%"
              max-width="100%"
              object-fit="cover"
              object-position="30% 50%"
              md-height="600px"
              sm-height="400px"
            />
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            width="60%"
            padding="40px 0px 0px 90px"
            display="flex"
            flex-direction="column"
            justify-content="center"
            lg-padding="0px 0px 0px 50px"
            md-width="100%"
            md-padding="0px 35px 80px 35px"
            sm-padding="0px 0 80px 0"
          >
            <Text
              margin="0px 0px 30px 0px"
              color="--dark"
              font="normal 700 42px/1.2 --fontFamily-sans"
            >
              Наші пропозиції
            </Text>
            <Box
              min-width="100px"
              margin="30px 0px 30px 0px"
              display="grid"
              grid-template-columns="repeat(2, 1fr)"
              lg-margin="50px 0px 0px 0px"
              md-margin="40px 0px 0px 0px"
              sm-grid-template-columns="1fr"
              sm-grid-template-rows="auto"
            >
              <Box
                min-width="100px"
                padding="0px 0px 0 15px"
                border-width="0 0 0 1px"
                border-style="solid"
                border-color="#d4d5db"
                margin="0px 0px 30px 0px"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="normal 600 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
                >
                  Уроки водіння для початківців
                </Text>
                <Text
                  margin="15px 0px 0px 0px"
                  color="#75767d"
                  font="300 18px --fontFamily-sansHelvetica"
                >
                  Структурований підхід до навчання: Наші уроки для початківців
                  спрямовані на розвиток впевненості та навичок з нуля з
                  акцентом на безпеку та базові техніки водіння.
                  <br />
                  <br />
                  Безпека понад усе: Наголос на розумінні та дотриманні правил
                  дорожнього руху.
                  <br />
                  <br />
                  Основи керування автомобілем: Навчіться надійно керувати та
                  обслуговувати автомобіль.
                </Text>
              </Box>
              <Box
                min-width="100px"
                padding="0px 0px 0 15px"
                border-width="0 0 0 1px"
                border-style="solid"
                border-color="#d4d5db"
                margin="0px 0px 30px 0px"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="normal 600 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
                >
                  Поглиблені курси водіння
                </Text>
                <Text
                  margin="15px 0px 0px 0px"
                  color="#75767d"
                  font="300 18px --fontFamily-sansHelvetica"
                >
                  Вдосконалення навичок: Для тих, хто хоче вдосконалити свої
                  навички водіння, ми пропонуємо курси з безпечного водіння,
                  нічного водіння та поводження в надзвичайних ситуаціях.
                  <br />
                  <br />
                  Захисне водіння: Методи захисту себе та інших на дорозі.
                  <br />
                  <br />
                  Нічне водіння: Спеціалізовані практики для водіння в умовах
                  недостатнього освітлення.
                </Text>
              </Box>
              <Box
                min-width="100px"
                padding="0px 0px 0 15px"
                border-width="0 0 0 1px"
                border-style="solid"
                border-color="#d4d5db"
                margin="0px 0px 30px 0px"
                min-height="80px"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="normal 600 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
                >
                  Спеціалізовані симуляції водіння
                </Text>
                <Text
                  margin="15px 0px 0px 0px"
                  color="#75767d"
                  font="300 18px --fontFamily-sansHelvetica"
                >
                  Реалістичні сценарії: Використання найсучасніших симуляторів
                  для підготовки студентів до реальних викликів під час водіння.
                  <br />
                  <br />
                  Погодні умови: Тренування під час дощу, снігу та за інших
                  погодних умов.
                  <br />
                  <br />
                  Реагування на надзвичайні ситуації: Впевнене вирішення
                  раптових аварійних ситуацій на дорозі.
                </Text>
              </Box>
              <Box
                min-width="100px"
                min-height="80px"
                padding="0px 0px 0 15px"
                border-width="0 0 0 1px"
                border-style="solid"
                border-color="#d4d5db"
                margin="0px 0px 35px 0px"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="normal 600 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
                >
                  Підготовка до дорожніх випробувань
                </Text>
                <Text
                  margin="15px 0px 0px 0px"
                  color="#75767d"
                  font="300 18px --fontFamily-sansHelvetica"
                >
                  Підготовка до іспитів: Комплексні програми підготовки до
                  впевненого складання іспитів з водіння.
                  <br />
                  <br />
                  Імітаційні тести: Реалістичні тестові сценарії для зменшення
                  тривожності та підвищення продуктивності.
                  <br />
                  <br />
                  Зворотній зв'язок та вдосконалення: Детальний зворотній
                  зв'язок від викладачів для покращення конкретних навичок.
                </Text>
              </Box>
              <Box
                min-width="100px"
                padding="0px 0px 0 15px"
                border-width="0 0 0 1px"
                border-style="solid"
                border-color="#d4d5db"
                margin="0px 0px 30px 0px"
                min-height="80px"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="normal 600 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
                >
                  Гнучкі можливості планування
                </Text>
                <Text
                  margin="15px 0px 0px 0px"
                  color="#75767d"
                  font="300 18px --fontFamily-sansHelvetica"
                >
                  Зручний графік: Ми пропонуємо різні варіанти розкладу, щоб
                  навчання водінню зручно вписувалося у ваше життя.
                  <br />
                  <br />
                  Заняття на вихідних: Ідеально підходять для студентів та
                  працюючих професіоналів.
                  <br />
                  <br />
                  Вечірні сесії: Чудово підходить для тих, хто віддає перевагу
                  пізньому графіку.
                </Text>
              </Box>
              <Box
                min-width="100px"
                min-height="80px"
                padding="0px 0px 0 15px"
                border-width="0 0 0 1px"
                border-style="solid"
                border-color="#d4d5db"
                margin="0px 0px 35px 0px"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="normal 600 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
                >
                  Різноманітність транспортних засобів
                </Text>
                <Text
                  margin="15px 0px 0px 0px"
                  color="#75767d"
                  font="300 18px --fontFamily-sansHelvetica"
                >
                  Широкий вибір автомобілів: Від компактних автомобілів до
                  позашляховиків - навчіться їздити на транспортному засобі,
                  який найкраще відповідає вашим потребам.
                  <br />
                  <br />
                  Ручна та автоматична коробка передач: Варіанти для вивчення
                  обох типів трансмісій.
                  <br />
                  <br />
                  Новітні моделі: Навчання на новітніх моделях автомобілів з
                  сучасними функціями.
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section
        padding="80px 0 80px 0"
        sm-padding="60px 0px 60px 0px"
        quarkly-title="Advantages/Features-24"
      >
        <Override
          slot="SectionContent"
          flex-direction="row"
          flex-wrap="wrap"
          sm-min-width="280px"
        />
        <Box
          display="flex"
          width="50%"
          flex-direction="column"
          justify-content="flex-start"
          align-items="flex-start"
          lg-width="100%"
          lg-align-items="flex-start"
          lg-margin="0px 0px 60px 0px"
          sm-margin="0px 0px 40px 0px"
          sm-padding="0px 0px 0px 0px"
          lg-flex-direction="row"
          lg-flex-wrap="wrap"
        >
          <Text
            margin="0px 0px 48px 0px"
            color="--dark"
            font="--headline2"
            lg-text-align="center"
            sm-font="--headline3"
            lg-width="100%"
          >
            Готові розпочати свою подорож за кермом?
          </Text>
          <Box
            display="flex"
            align-items="flex-start"
            margin="0px 0px 32px 0px"
            lg-width="100%"
            md-width="100%"
            md-margin="0px 0px 32px 0px"
            md-padding="0px 0px 0px 0px"
          >
            <Box margin="0px 0px 0px 22px" sm-margin="0px 0px 0px 12px">
              <Text
                margin="0px 0px 0px 0px"
                color="--greyD3"
                font="--base"
                lg-text-align="left"
              >
                Наша команда рада запропонувати вам індивідуальну консультацію,
                яка допоможе вам зорієнтуватися на шляху навчання в Vexolux Pro.
                Для отримання більш детальної інформації про наші послуги та
                підбору найкращої програми для вас, будь ласка, зв'яжіться з
                нами або відвідайте нашу школу.
                <br />
                <br />
                Дозвольте нам посадити вас за кермо вашого власного водійського
                успіху!
                <br />
                <br />
                Рухайтеся вперед з упевненістю. Приєднуйтесь до нас сьогодні та
                прискорюйте рух до своїх цілей!
              </Text>
            </Box>
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
